import React,{useState,useRef} from 'react'
import { Input,Button, message } from 'antd';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { login } from '../service';
import {history} from '../helper'
import './Login.css';


const Login =()=>{


    const [username,setId] = useState()
    const [password,setPasswd] = useState()


    const sendLogin= async (e)=>{
     
     
        e.preventDefault();

        if(!username || !password){
            message.errot("Please enter your ID and password.",1)

            return;
        }

     

        const res = await login({username,password});

        if(res.result === "-1"){

            message.error(res.msg, 1);
            setPasswd('');

            return;

        }else{

            sessionStorage.username = username;
            history.push('/Orders');
        }
       

    }




    return(
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form" onSubmit={sendLogin}>
                        {/* <span className="login100-form-title p-b-26">
                         Welcome
                        </span> */}
                        <span className="login100-form-title p-b-48">

                            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="img"/>
					
					    </span>
                        <div className="wrap-input100">

                            <Input placeholder="Username" className="input100" onChange={(e)=>setId(e.target.value)}/>

					    </div>
                        <div className="wrap-input100">

                            <Input.Password placeholder="Password" className="input100" onChange={(e)=>setPasswd(e.target.value)}/>

                        </div>
                        <div className="container-login100-form-btn">
						<div className="wrap-login100-form-btn">
                            <Button type="primary" htmlType="submit" shape="round" className='login100-form-btn' onClick={sendLogin}>
                                Login
                            </Button>
							
							
						</div>
					</div>


                    </form>

                </div>

            </div>

        </div>

    )
}


export default Login;