
import React, { useEffect} from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { OrderList,Login } from './containers';
import { history } from './helper';

import './App.css';



function App() {

  return (
  <Router history={history}>
    <Switch>
      <Route path="/Orders" component={OrderList} />
      <Route path="/Login" component={Login} />
      <Redirect from="*" to="/Login" />
    </Switch>

  </Router>

  );
}

export default App;
