import axios from "axios";


const headers ={

    'Content-type': 'application/json',
    'apikey': process.env.REACT_APP_API_KEY
}

const getOrders= async (param)=>{

    const {data} = await axios.post('api/Bc/01_getOrder',param,{headers})

    return data


}

export {getOrders}