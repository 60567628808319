import React from 'react'
import { Layout,Image } from 'antd';


const { Header, Footer,Content } = Layout;

const LineMenuLayout=({children})=>{

    return(
        <Layout>
        <Header className="d-flex align-items-center" style={{backgroundColor:'#9499B7',color:'#ffffff', marginBottom:'50px'}} >
           <Image src={process.env.PUBLIC_URL + '/logo.png'} alt="img" />
           <div className="Layout_title">Workflow System</div>
        </Header>
        <Content>
            <div className="Layout_contentWrapper">
                {children}
            </div>
          
        </Content>
        <Footer>©Copyright Boranet SEO</Footer>


  </Layout>

    )

}


export default LineMenuLayout;