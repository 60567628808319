import { jsontoarray } from './utils';

const makeExcelData =(listdata)=>{

    let res_data={}

    //title사용 안함
    //let data=[ [{value:'SpringBlinds Order Sheet',fontWeight: 'bold',span:3}], [{},{},{}]]

    let data=[]

    const title=[

        {value:'DATE',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Order#',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Customer',fontWeight: 'bold',borderColor:"#0000"},
        {value:'SKU',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Fabric',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Color',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Quantity',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Width',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Height',fontWeight: 'bold',borderColor:"#0000"},
        {value:'MtTYPE',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Roll Direction',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Control Type',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Valance Style',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Bottom BarStyle',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Module',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Remote',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Charger',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Holder',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Glide Track',fontWeight: 'bold',borderColor:"#0000"},
        {value:'Memo',fontWeight: 'bold',borderColor:"#0000"}
     
    ]

    data.push(title)

    for (let index = 0; index < listdata.length; index++) {
        const obj = listdata[index];

        const arr = jsontoarray(obj)
        data.push(arr)
        
    }

    const columns = [
         {width:20},
         {},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 20},
         { width: 40 }
       ]

       res_data.data = data;
       res_data.columns = columns;




    return res_data;



}

export default makeExcelData;