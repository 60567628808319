const jsontoarray=(obj)=>{

    const keys = Object.keys(obj);

    let arrs=[]

    for (let index = 0; index < keys.length; index++) {

      
        const key = keys[index];

        if(key !=='timestamp'){

            const value = obj[key];
            const Datatype = (typeof value) === 'number' ? Number : String
            const tmp={type:Datatype,value,wrap:true}
            arrs.push(tmp)
        }
        
    }

    return arrs;
    

}

export {jsontoarray}