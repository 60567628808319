import axios from "axios";


const headers ={

    'Content-type': 'application/json',
    'apikey': process.env.REACT_APP_API_KEY
}


const login= async (param)=>{

    const {data} = await axios.post('/api/User/01_login',param,{headers})

    return data

}

export {login}