import React,{useState,useRef} from 'react'
import moment from 'moment'
import writeXlsxFile from 'write-excel-file'

import { Row,Col,Button,Table,message,DatePicker,Tooltip } from 'antd';
import { DownloadOutlined,SearchOutlined } from '@ant-design/icons';

import { makeExcelData } from '../helper';

import { getOrders } from '../service/Order';
import { history } from '../helper';
import { LineMenuLayout } from '../layouts';

const OrderList =()=>{

    if(!sessionStorage.username){

      history.push('/Login')


    }


    const [listdata,setListData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [pagenation,setpagenation] = useState({current:1, pageSize:15})
    const [pageData,setPageData] = useState({page:1,page_size:15})
    

    const start_dt = useRef();
    const end_dt = useRef();

   


    const columns=[
        {
            title: 'NO',
            key: 'index',
            render:(text, record, index) => {
                return index+((pageData.page-1)*pageData.page_size)+1
             }
          },
          {
            title:'DATE',
            dataIndex:'order_dt',
            key:'order_dt'

        },
        {
            title:'#Order',
            dataIndex:'OrderNum',
            key:'OrderNum'

        },
        {
          title:'Customer',
          dataIndex:'Customer',
          key:'Customer'

        },
        {
            title:'SKU',
            dataIndex:'Sku',
            key:'Sku'

        },
    
        {
            title:'Fabric',
            dataIndex:'Fabric',
            key:'Fabric'

        },
        {
            title:'Color',
            dataIndex:'Color',
            key:'Color'

        },
         
        {
          title:'Quantity',
          dataIndex:'Quantity',
          key:'Quantity'

       },
        {
            title:'Width',
            dataIndex:'Width',
            key:'Width'

        },
        {
            title:'Height',
            dataIndex:'Height',
            key:'Height'

        },
        {
            title:'Mount',
            dataIndex:'MtTYPE',
            key:'MtTYPE'

        },
        {
          title:'Roll',
          dataIndex:'Roll',
          key:'Roll'

       },
        {
            title:'Control Type',
            dataIndex:'ControType',
            key:'ControType',
            ellipsis: {
                showTitle: false,
              },
            render: ControType => (
                <Tooltip placement="topLeft" title={ControType}>
                  {ControType}
                </Tooltip>
              ),

        },
        {
            title:'Valance Style',
            dataIndex:'ValcneStyle',
            key:'ValcneStyle',
            ellipsis: {
                showTitle: false,
              },
            render: ValcneStyle => (
                <Tooltip placement="topLeft" title={ValcneStyle}>
                  {ValcneStyle}
                </Tooltip>
              ),
          

        },
        {
            title:'BottomBarStyle',
            dataIndex:'BottomBarStyle',
            key:'BottomBarStyle',
            ellipsis: {
                showTitle: false,
              },
            render: BottomBarStyle => (
                <Tooltip placement="topLeft" title={BottomBarStyle}>
                  {BottomBarStyle}
                </Tooltip>
              ),
           

        },
        {
            title:'Module',
            dataIndex:'Module',
            key:'Module',
            ellipsis: {
                showTitle: false,
              },
            render: Module => (
                <Tooltip placement="topLeft" title={Module}>
                  {Module}
                </Tooltip>
              ),

        },
        {
            title:'Remote',
            dataIndex:'Remote',
            key:'Remote',
            ellipsis: {
                showTitle: false,
              },
            render: Remote => (
                <Tooltip placement="topLeft" title={Remote}>
                  {Remote}
                </Tooltip>
              ),
            

        },
        {
            title:'Charger',
            dataIndex:'Charger',
            key:'Charger',
            ellipsis: {
                showTitle: false,
              },
            render: Charger => (
                <Tooltip placement="topLeft" title={Charger}>
                  {Charger}
                </Tooltip>
              ),


        },
        {
            title:'Holder',
            dataIndex:'Holder',
            key:'Holder',
            ellipsis: {
                showTitle: false,
              },
            render: Holder => (
                <Tooltip placement="topLeft" title={Holder}>
                  {Holder}
                </Tooltip>
              ),
            

        },
        {
          title:'Glide Track',
          dataIndex:'Glide',
          key:'Glide',
          ellipsis: {
              showTitle: false,
            },
          render: Glide => (
              <Tooltip placement="topLeft" title={Glide}>
                {Glide}
              </Tooltip>
            ),
          

      },
        {
            title:'Memo',
            dataIndex:'Memo',
            key:'Memo',
            ellipsis: {
                showTitle: false,
              },
            render: Memo => (
                <Tooltip placement="topLeft" title={Memo}>
                  {Memo}
                </Tooltip>
              ),

        },

    ]

    const handleTableChange = async (pagenation, filters, sorter) => {

        console.log('pagenation',pagenation)
        const pager = { pagenation };

        console.log('pager',pager)
        
        pager.current = pagenation.current;
       
        setpagenation(pager);

        setPageData({page:pagenation.current,page_size:pagenation.pageSize})

    }


    const disabledDateFirst =(current)=> {

     
       
        return current > moment().endOf('day');
      }

      const disabledDateSecond=(current)=> {
       

        const start_date = start_dt.current;

        return  current < moment(start_date,'YYYY-MM-DD')
    }


    const selectStart=(date, dateString)=>{

     

        start_dt.current = dateString;

    }

    const selectEnd=(data,dateString)=>{

     
        end_dt.current =dateString; 

    }


    const getData = async ()=>{

        setListData([]);

        if( !start_dt.current || !end_dt.current){

            message.error('Please choose a date to search.',1)
            return;
        }

        setLoading(true);

        const param={start_dt:start_dt.current, end_dt:end_dt.current}

        const data = await getOrders(param);

        setLoading(false);

        if(Array.isArray(data)){

            setListData(data);
        
        }else{

            alert('서버 응답 오류')
        }

        

    }


    const downExcel= async ()=>{

        if(listdata.length < 1 ){
            message.error(' No Data!!! ',1);
            return;
        }

        const today=moment().format('YYYY-MM-DD');
        const filename=`${today}_Order.xlsx`

        const {data, columns} = makeExcelData(listdata)

        console.log('data',data);

         await writeXlsxFile(data, {
             columns,
             fontSize: 10,
             sheet: 'Order',
             fileName: filename
           })

    }


    return(
       <LineMenuLayout>
            <Row justify="center" align="middle" style={{marginBottom:'10px'}}>
               <Col span={23} className="d-flex justify-content-end align-items-center">
                  
                 <div className="">
                    <DatePicker onChange={selectStart}  disabledDate={disabledDateFirst} placeholder="Start Date" style={{marginRight:'25px'}}/>
                    <DatePicker onChange={selectEnd}  disabledDate={disabledDateSecond}  placeholder="End Date" />
                    <Button type="primary" icon={<SearchOutlined />} onClick={getData} style={{backgroundColor:'#d56262', marginLeft:'10px', borderColor:'#d56262'}}>Get Data</Button>

                 </div>
                 
                 <div className="p-3">
                   
                    <Button type="primary" icon={<DownloadOutlined />} onClick={downExcel} style={{backgroundColor:'#5195df', marginRight:'5px', borderColor:'#5195df'}}>Export Excel</Button>
                </div>
               
               </Col>

           </Row>
           <Row justify="center" align="middle">
                <Col span={24}>
                <Table columns={columns}
                    rowKey={record => record.timestamp}
                    dataSource={listdata} 
                    loading={loading}
                    pagination={pagenation}
                    size="small"
                    onChange={handleTableChange}
                />

                </Col>
           </Row>
           


       </LineMenuLayout>

    )


}



export default OrderList;